import React, { useEffect } from "react";

export default function SubBar() {

  useEffect(() => {
    if (typeof window !== "undefined") {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, [])

  return (
    <div className="asideWrapper">
      <div className="stickySidebar">
        <ins class="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-8335251025540698"
          data-ad-slot="9771896311"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      </div>
    </div>
  );
}
