import React from "react";
import { Link } from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image"

export default function LargeBlogCard({ posts,dateOptions }) {

  return (
    <div className="blogPost">
      <h4>Du kanske också gillar </h4>
      <div className="relatedPosts">
        {posts &&
          posts.map((post, index) => {
            return (
              <Link to={"/blogg/" + post.frontmatter.pathname + "/"} key={post.id} className="relatedLink">
                <div className="related">
                  <GatsbyImage image={post.frontmatter.featuredImage.childImageSharp.gatsbyImageData} className="relatedImage"/>
                  <h5 className="font-bold">{post.frontmatter.title}</h5>
                  <p>{new Date(post.frontmatter.date).toLocaleDateString('se-SE', dateOptions)}</p>
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
}
