import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from "../SEO";
import { FacebookShareButton, FacebookIcon, TwitterIcon, TwitterShareButton, PinterestIcon, PinterestShareButton } from "react-share"
import RelatedPosts from "./RelatedPosts"
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Mailchimp from "./MailChimp"
import Sidebar from "../Wrappers/SideBar"
import "../../styles/sections.scss"


export default function BlogPost(props) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  const post = props.data.markdownRemark;
  const url = "https://www.framgangspillret.com/" + props.path;
  const image = post.frontmatter.featuredImage?.childImageSharp.gatsbyImageData;
  const imageURL = "https://www.framgangspillret.com" + image?.images.fallback.src

  const others = (props.data.blog.posts);
  const related = others


  return (
    <>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        article={true}
        image={image?.images.fallback.src}
      />
      <Navbar />
      {post.frontmatter.sponsored && <div className="w-full py-3 bg-gray-100"><p className="flex items-center px-4 sm:px-8 lg:px-16 xl:px-20 justify-between max-w-screen-xl mx-auto">Inlägget innehåller reklam genom annonslänkar.</p></div>}

      <div className="mainSection sectionMaxWidth">
        <section className="mainWrapper">
          <article className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" />}
            <div>
              <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-4 mt-2">{post.frontmatter.title}</h1>
              <small className="text-md	font-medium ">
                {post.frontmatter.author},{" "}
                {post.frontmatter.date &&
                  new Date(post.frontmatter.date).toLocaleDateString('sv-SV', options)}
              </small>
              <br />
              <div dangerouslySetInnerHTML={{ __html: post.html }} className="blogText mt-4" />
              <div className="shareButtons">
                <FacebookShareButton url={url} className="share">
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton url={url} title={post.frontmatter.title} className="share">
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <PinterestShareButton
                  url={url}
                  media={imageURL}
                  windowWidth={1000}
                  windowHeight={730}>
                  <PinterestIcon size={32} round={true} />
                </PinterestShareButton>

              </div>
              <br />
              <br />
              <RelatedPosts posts={related} dateOptions={options} />
              <br />
              <Mailchimp />
              <br />


            </div>

          </article>
        </section>
        <Sidebar />

      </div>
      <br />
      <br />
      <Footer dark={true} />
    </>
  );
}

var skip = 5

export const query = graphql`
  query BlogQuery($slug: String!, $skip: Int) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        author
        sponsored
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    },
    blog: allMarkdownRemark(
      filter: { fileAbsolutePath: {regex : "/(/blog/)/"} },
      sort: { fields: [frontmatter___date], order: DESC},
      skip: $skip,
      limit: 3,
    ) {
      posts: nodes {
        frontmatter {
          date
          title
          pathname
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        id
      }
    }
  }
`;
