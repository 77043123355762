import React, { useState, useEffect } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = "https://framgangspillret.us5.list-manage.com/subscribe/post?u=fc811a6fc1f41cadbc5a66376&amp;id=130974cecd";


const CustomForm = ({ status, message, onValidated }) => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (email &&
            firstName) {

            const formdata = {
                EMAIL: email,
                FNAME: firstName,
            }
            onValidated(formdata);
        }
    }

    useEffect(() => {
        if (status === "success") clearFields();
    }, [status])

    const clearFields = () => {
        setFirstName('');
        setEmail('');
    }


    return (
        <div className="newsletterCard">
            <form
                className="mc__form"
                onSubmit={(e) => handleSubmit(e)}
            >
                <h3 className="m-0">Prenumerera på vårt nyhetsbrev</h3>
                <p>Få artiklar om personlig utveckling, hälsa och ekonomisk frihet direkt i din inkorg!</p>

                {status === "sending" && (
                    <div
                        className="mc__alert mc__alert--sending"
                    >sending...</div>
                )}
                {status === "error" && (
                    <div
                        className="mc__alert mc__alert--error"
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
                {status === "success" && (
                    <div
                        className="mc__alert mc__alert--success"
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}

                {status !== "success" ? (
                    <div className="mc__field-container">
                        <input
                            label="First Name"
                            onChange={(e) => setFirstName(e.target.value)}
                            type="text"
                            value={firstName}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full sm:w-60 p-2.5 mt-3"
                            placeholder="Namn"
                            isRequired
                        />
                        <input
                            label="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            value={email}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full sm:w-60  p-2.5 mt-3"
                            placeholder="E-mail"
                            isRequired
                        />

                    </div>
                ) : null}

                {/*Close button appears if form was successfully sent*/}
                {status !== "success" &&
                    <button
                        className="text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 mt-3 text-center w-full sm:w-60 "
                        type="submit"
                    >Prenumerera</button>
                }
            </form>
        </div>

    );
};



// use the render prop and your custom form
const Mailchimp = () => (
    <div className="bg-gray-100 shadow-md border border-gray-200 p-6 rounded-xl">
        <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
                <div>
                    <CustomForm status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)} />

                </div>
            )}
        />
    </div>
)

export default Mailchimp;